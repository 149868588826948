import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Fade } from "react-reveal"
import Seo from "../../../components/seo"

function Page() {
	return (
		<main className="grid w-screen min-h-screen place-items-center bg-primary-50">
			<Seo
				title="Angebot für Civitalis GmbH"
				description="Angebot für Civitalis GmbH"
				noIndex
			/>
			<div className="flex flex-col items-center justify-center gap-20 sectionwidth">
				<StaticImage
					quality={100}
					width={400}
					src="../../../images/logo.png"
					alt=""
					placeholder=""
					loading="eager"
				/>

				<div className="font-bold text-center h1">
					<p>02.02.2022</p>
					<h1>
						Angebote für die{" "}
						<span className="text-primary">Civitalis GmbH</span>
					</h1>
				</div>

				<Fade up>
					<div className="flex flex-wrap justify-center gap-8">
						<Link
							to="/angebot/4894/recruitervermittlung"
							className="btn btn-secondary"
						>
							Senior Recruiter Vermittlung
						</Link>
						<Link
							to="/angebot/4894/recruiter-mieten"
							className="btn btn-secondary"
						>
							Recruiter mieten
						</Link>
						<Link to="/angebot/4894/website" className="btn btn-secondary">
							Relaunch von civitalis.eu
						</Link>
					</div>
				</Fade>
			</div>
		</main>
	)
}

export default Page
